import { refresh } from '@api/authentication';
import { getServicability } from '@api/store';
import { TAddon, TAddress, TCartItem, TData, TImage, TInventory, TOption, TOrderItem, TServiceLocation } from '@components/types';
import moment from 'moment';
import { ImageLoaderProps } from 'next/image';
import router from 'next/router';

export const requestLogin = (next: string) => {
    localStorage.removeItem("token");
    refresh().then((res) => {
        localStorage.setItem("token", res.access_token);
    }).catch((e) => {
        console.debug("Error while refresh request");
        console.debug(e);
    })
    router.push(`/login?next=${next}`);
}

export const sanityIoImageLoader = ({ src, width, quality }: ImageLoaderProps) => {
    if (src.startsWith("http")) {
        return `https://cdn.jhattse.com/resize?width=${width}&url=${src}&quality=${quality || 75}&type=webp`
    } else {
        return `https://cdn.jhattse.com/resize?width=${width}&file=${src}&quality=${quality || 75}&type=webp`
    }
}

export const staticImageLoader = ({ src, width, quality }: ImageLoaderProps) => {
    if (src.startsWith("http")) {
        return `https://cdn.jhattse.com/${src}`
    } else {
        return `https://cdn.jhattse.com/${src}`
    }
}

export const getFilteredResults = (input: string, data: TData[]) => {
    if (data == undefined) {
        return new Array < TOption > ();
    }
    const inputValue = input != undefined ? input.trim().toLowerCase() : undefined;
    const inputLength = inputValue == undefined ? 0 : inputValue.length;

    const filteredResults = inputLength === 0 ? data : data.filter(store =>
        store?.name?.toLowerCase().slice(0, inputLength) === inputValue
    );

    const subFilteredResults = inputLength === 0 ? data : data.filter(store =>
        store?.name?.toLowerCase().includes(inputValue)
    );
    let result = [];
    if (filteredResults?.length > 5) {
        result = filteredResults;
    } else {
        result = Array.from(new Set([...filteredResults, ...subFilteredResults]));
    }
    return result.map((store) => { return { value: store?.id?.toString(), label: store.name } });
};


export const getSafeUrl = (name: string) => {
    return (name || '').replace(/ +/g, '-').replace(/[.,\?:&\+\/%\|]+/g, '-').replace(/-+/g, '-').toLowerCase();
}

export const range = (start: number, stop: number, step: number) =>
    Array.from({ length: (stop - start) / step + 1 }, (_, i) => start + (i * step))

export const checkLocalhost = () => { return typeof (window) !== "undefined" && window.location.hostname.includes("localhost") }
export const humanizeCurrency = (x: number) => { return x?.toLocaleString("en-IN", { style: "currency", currency: "INR" }).split('.')[0] }
export const humanizeNumber = (x: number) => { return x?.toLocaleString('en-IN') }
export const getBusinessUrl = (url: string) => {
    return checkLocalhost() ? url : `https://business.jhattse.com${url}`
}

export const getImageUrl = (productImages: TImage[]) => {
    return (productImages || [])?.length > 0 ? productImages[0].url : "https://jhattse.com/public/consumer/placeholder/product_square.png";
}

export const getSortedImages = (productImages: TImage[]) => {
    if ((productImages || [])?.length > 0) {
        const images = [...productImages?.filter((x) => x.type === "main"), ...productImages?.filter((x) => x.type !== "main")]
        return images.sort((a, b) => a.id - b.id)
    } else {
        return [{ url: "https://jhattse.com/public/consumer/placeholder/product_square.png", description: "No image available", id: 0, product_id: 0, type: "other" } as TImage]
    }
}

export const getImageObject = (productImages: TImage[]) => {
    if ((productImages || [])?.length > 0) {
        const images = [...productImages.filter((x) => x.type === "main"), ...productImages.filter((x) => x.type !== "main")]
        return images.sort((a, b) => a.id - b.id)[0]
    } else {
        return { url: "https://jhattse.com/public/consumer/placeholder/product_square.png", description: "No image available", id: 0, product_id: 0, type: "other" } as TImage
    }
}

export const calculateTax = (orderItems: TOrderItem[]) => {
    return Array.from(orderItems)?.reduce((prevTax, currentTax) => prevTax + (currentTax?.total_tax || 0), 0)
}

export const calculateCost = (orderItems: TOrderItem[]) => {
    return Array.from(orderItems)?.reduce((prevCost, currentCost) => prevCost + (currentCost?.total_cost || 0), 0)
}

export function groupBy<T>(arr: T[], fn: (item: T) => any) {
    return arr?.reduce < Record < string, T[] >> ((prev, curr) => {
        const groupKey = fn(curr);
        const group = prev[groupKey] || [];
        group.push(curr);
        return { ...prev, [groupKey]: group };
    }, {});
}

export const trimToLength = (name: string, charLength: number) => {
    return name?.length > charLength ? `${name?.slice(0, charLength)}...` : name;
}


export const getColor = (rating?: number) => {
    if (rating === undefined) {
        return 1
    }
    return rating < 2.5 ? 1 : (rating < 4 ? 2 : (rating < 4.5 ? 3 : 3))
}

export const getLength = (iter: Array<any> | undefined | null) => {
    if (iter === null || iter === undefined) {
        return 0
    } else {
        return iter.length;
    }
}

export function getFirst<Type>(iter: Type[] | undefined | null): Type {
    if (iter === null || iter === undefined || iter.length === 0) {
        return {} as Type
    } else {
        return iter[0];
    }
}

export function suggestColor(num: number, isText: boolean = false) {
    if (num > 4) return isText ? 'text-success-500' : 'bg-success-500';
    else if (num > 3) return isText ? 'text-success-300' : 'bg-success-300';
    else if (num > 2) return isText ? 'text-yellow-400' : 'bg-yellow-400';
    else if (num > 1) return isText ? 'text-yellow-300' : 'bg-yellow-300';
    else return isText ? 'text-error-300' : 'bg-error-300';
}

export function formatDate(date: string) {
    return moment.utc(date).format('DD/MM/YYYY');
}

export const PriceCalculation = (component: TCartItem) => {
    // All Required Element
    let addOnPrice = component?.addon?.filter((a) => a?.enable != false)?.reduce((prev: number, addon) => prev + (addon?.price || addon?.inventory?.price || 0), 0)
    let price = ((component?.inventory?.price || component?.inventory?.mrp || component?.product?.mrp) + (addOnPrice || 0));
    return (price * component?.quantity)?.toFixed(2)
}

export const modalPriceCalculation = (inventory: TInventory, quantity: number, addons: TAddon[]) => {
    let addOnPrice = addons?.reduce((prev: number, addon) => prev + (addon?.price || addon?.inventory?.price || 0), 0)
    let price = ((inventory?.price || inventory?.mrp) + (addOnPrice || 0));
    return (price * quantity)?.toFixed(2)
}
export const getAddress = (address: TAddress, placeholder: string = "") => {
    const fullAddress = [address?.house_number, address?.street_name, address?.locality, address?.city?.name, address?.state?.name, address?.pincode].filter(element => { return element?.length > 0; }).join(', ');
    return fullAddress?.length > 0 ? fullAddress : placeholder;
}


export const checkStoreAvailablity = (store_id: number, online_ordering: boolean = false) => {
    const userLocation = JSON.parse(localStorage.getItem("location"))
    getServicability([store_id], userLocation?.Location?.city_id)?.then((res) => {
        if (res?.length > 0 && online_ordering) {
            return true
        }
        else if (res?.length > 0) {
            return true
        }
        else {
            return false
        }
    })

}

export const checkStoreAvailablityWithCity = (city_ids: number[], selectedAddress: TAddress) => {
    if (city_ids?.includes(selectedAddress?.city_id)) {
        return true
    }
    else {
        return false
    }
}