import axios from "axios";
import { handleResponse, HEADERS, SERVER_HOST } from "@api";
import { TStore } from "@components/types";
import { getLocation } from "@core/geolocation";

export const getNearestStores = async (category_id: number = null, pageNumber: number = 0, pageSize: number = 10, askLocation: boolean = true) => {
    let location = askLocation ? await getLocation() : null;
    const res = await axios.get(`${SERVER_HOST}/api/v1/stores/?${category_id === null ? "" : `category_id=${category_id}&`}skip=${pageNumber * pageSize}&limit=${pageSize}&lat=${location?.latitude || 0}&lon=${location?.longitude || 0}`,
        { headers: HEADERS });
    const result: TStore[] = handleResponse(res);
    return result
}

export const getLaunchedStores = async (category_id: number = null, pageNumber: number = 0, pageSize: number = 10) => {
    const res = await axios.get(`${SERVER_HOST}/api/v1/stores/newlaunch?${category_id === null ? "" : `category_id=${category_id}&`}skip=${pageNumber * pageSize}&limit=${pageSize}`,
        { headers: HEADERS });
    const result: TStore[] = handleResponse(res);
    return result
}

export async function fetchNearestStores(
    isDelivery: boolean = false,
    isPickup: boolean = false,
    categoryId: number = null,
    orderingOn: string = null,
    skip: number = 0,
    limit: number = 10,
) {
    const location = await getLocation();
    const response = await axios.get(`${SERVER_HOST}/api/v1/stores/?${categoryId ? `category_id=${categoryId}&` : ''}${orderingOn ? `ordering_on=${orderingOn}&` : ''}${isDelivery ? `is_delivery=${isDelivery}&` : ''}${isPickup ? `is_pickup=${isPickup}&` : ''}skip=${skip}&limit=${limit}&lat=${location.latitude}&lon=${location.longitude}`, {
        headers: HEADERS
    });
    const data: TStore[] = handleResponse(response);
    return data;
};

export const saveStore = async (store: TStore) => {
    let axiosMethod = store.id == null ? axios.post : axios.put;
    const res = await axiosMethod(`${SERVER_HOST}/api/v1/stores/${store.id == null ? "" : store.id}`, JSON.stringify(store), {
        headers: { "Content-Type": "application/json", "Authorization": `Bearer ${localStorage.getItem("token")}` }
    })
    const result: TStore = handleResponse(res)
    return result;
}

export const getStore = async (id: number) => {
    if (typeof (navigator) === "undefined" || navigator.onLine) {
        const res = await axios.get(`${SERVER_HOST}/api/v1/stores/${id}`, {
            headers: HEADERS
        })
        const result: TStore = handleResponse(res);
        return result;
    } 
}

export const getMyStores = async () => {
    if (typeof (navigator) === "undefined" || navigator.onLine) {
        const res = await axios.get(`${SERVER_HOST}/api/v1/stores/?user_id=${JSON.parse(localStorage.getItem("profile"))?.id}`, {
            headers: HEADERS
        })
        const result: TStore[] = handleResponse(res);
        return result;
    } 
}

// Get All Store Timing
export const getStoreImages = async (store_id: number) => {
    let axiosMethod = axios.get;
    const res = await axiosMethod(`${SERVER_HOST}/api/v1/storeimages/?store_id=${store_id}`,
        { headers: { "Content-Type": "application/json", "Authorization": `Bearer ${localStorage.getItem("token")}` } })
    return handleResponse(res);
}

export const getStoreCharges = async (store_id: number) => {
    let axiosMethod = axios.get;
    const res = await axiosMethod(`${SERVER_HOST}/api/v1/storecharges/?store_id=${store_id}`,
        { headers: { "Content-Type": "application/json" } })
    return handleResponse(res);
}

// Get Store Meta
export const getStoreMeta = async (store: TStore) => {
    let axiosMethod = axios.get;
    let params: any = new URLSearchParams()
    params.append('store_id', store?.id.toString());
    const res = await axiosMethod(`${SERVER_HOST}/api/v1/storemeta/?${params.toString()}`,
        { headers: { "Content-Type": "application/json", "Authorization": `Bearer ${localStorage.getItem("token")}` } })
    return handleResponse(res);
}

export const getServicability = async (storeIds: number[] = [], city_id: number = null, pageNumber: number = 0, pageSize: number = 200) => {
    let axiosMethod = axios.get;
    let params: any = new URLSearchParams()
    for (let i = 0; i < storeIds.length; i++) {
        params.append('store_id', storeIds[i]?.toString());
    }
    if (city_id != null) {
        params.append('city_id', city_id.toString());
    }
    params.append('pageNumber', pageNumber.toString());
    params.append('pageSize', pageSize.toString());
    const res = await axiosMethod(`${SERVER_HOST}/api/v1/serviceability/?${params.toString()}`,
        { headers: { "Content-Type": "application/json", "Authorization": `Bearer ${localStorage.getItem("token")}` } })
    return handleResponse(res);
}