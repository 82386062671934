import { CarouselContainer } from '@components/container/carousel'
import { TAddon, TCartItem, TInventory, TProduct, TVariant } from '@components/types'
import { getFirst, getImageObject, humanizeCurrency, modalPriceCalculation, sanityIoImageLoader, trimToLength } from '@core/utils'
import { cartState } from '@recoil/atoms'
import { variantState } from '@recoil/atoms/variant'
import Image from 'next/image'
import React, { useEffect, useState } from 'react'
import { AiOutlineClose } from 'react-icons/ai'
import { FaMinus, FaPlus } from 'react-icons/fa'
import { MdEdit } from 'react-icons/md'
import { useRecoilState } from 'recoil'
import { SwiperSlide } from 'swiper/react'
import { inventoryByVariantId } from './variantSelector'

interface Props {
    showModal: boolean,
    product: TProduct,
    btnName?: string,
    inventory?: TInventory,
    quantity?: number
}

const Variant: React.FC<Props> = ({ product, showModal, inventory }) => {
    const [variant, setVariant] = useState<TVariant>();
    const [Isvariant, setIsVariant] = useRecoilState(variantState);
    const [cart, setCart] = useRecoilState(cartState);
    const variantId = getFirst(inventoryByVariantId(variant, product))?.variant_id || inventory?.variant_id;
    const [productQyt, setProductQyt] = useState(variantId != undefined && cart.get(variantId.toString())?.quantity || 1)
    const [addons, setAddons] = useState<TAddon[]>([])
    const [addonList, setAddonList] = useState<TAddon[]>([]);
    const [customizedItems, setCustomizedItems] = useState([])
    const [selectedItem, setSelectedItem] = useState<TCartItem>(null)

    useEffect(() => {
        if (selectedItem != null) {
            setProductQyt(selectedItem?.quantity)
            setAddonList(selectedItem?.addon)
        } else {
            setProductQyt(1)
            setAddonList([])
        }

        console.debug(selectedItem)
    }, [selectedItem])

    useEffect(() => {
        if (inventory != undefined) {
            setVariant(inventory?.variant)
        } else {
            setVariant(product?.variants?.find((variant) => (getFirst(inventoryByVariantId(variant, product))?.is_available == true)) as TVariant)
        }
    }, [inventory, product])



    const toggleAddon = (addOn: TAddon) => {
        let otherAddons: TAddon[] = addonList?.filter((x) => x?.addon_id !== addOn?.addon_id);
        if ((otherAddons?.length || 0) != (addonList?.length || 0)) {
            setAddonList(otherAddons);
        } else {
            setAddonList([...otherAddons, addOn])
        }
    };



    const onClickVariant = (value: TVariant) => {
        setVariant(value);
    }


    const onAddToCart = (product: TProduct, addons: TAddon[], inventory: TInventory, productQyt: number, internalId: string = null) => {
        console.debug(product, addons, inventory, productQyt, internalId)
        setIsVariant({ Isvariant: false, product: product, showModal: showModal, quantity: productQyt });
        const finalId = `${inventory?.variant?.id}${addons.length > 0 ? "_" + addons.sort((a, b) => a.id - b.id).map((x) => x.id).join("_") : ""}`
        const _internalId = (internalId?.length || 0) > 0 ? internalId : finalId;
        (variantId !== null && variantId !== undefined) && setCart((cart) => {
            if (cart.has(_internalId)) {
                let newQuantity = productQyt;
                if (newQuantity == 0 || newQuantity == undefined) {
                    cart.delete(_internalId)
                }
                else {
                    if (finalId != _internalId) {
                        cart.delete(_internalId)
                    }
                    cart.set(finalId, { internalId: finalId, product: product, quantity: newQuantity, inventory: inventory, deliverable: inventory?.store?.is_delivery || false, addon: addonList })

                }
            } else {
                cart.set(finalId || '', { internalId: finalId, product: product, quantity: productQyt, inventory: inventory, deliverable: inventory?.store?.is_delivery || false, addon: addonList })
            }
            window?.fbq('track', 'AddToCart', {
                content_name: `${product?.name || inventory?.product?.name}`,
                content_category: `${product?.category?.name || inventory?.product?.category?.name}`,
                content_ids: [`${finalId || product?.id || inventory?.id}`],
                content_type: 'product',
                value: inventory?.price || product?.mrp,
                currency: 'RS'
            });
            return new Map(cart);
        }
        )
    }

    useEffect(() => {
        setProductQyt(variantId != undefined && cart.get(variantId.toString())?.quantity || 1)
        setAddons(inventory?.addons || getFirst(inventoryByVariantId(variant, product))?.addons)
        setCustomizedItems(Array.from(cart.values()).filter((x) => x?.inventory?.id == (inventory?.id || getFirst(inventoryByVariantId(variant, product))?.id)))
        setAddonList([])
    }, [variant, product])

    const increase = (quantity: number) => {
        return setProductQyt(productQyt + quantity);
    };

    const closeModal = (state: boolean) => {
        setIsVariant({ ...Isvariant, showModal: state })
    }
    return (
        <div>
            {showModal ? (
                <>
                    <div
                        className="flex items-center overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none md:p-4 focus:outline-none font-manrope p-2"
                        onClick={() => {
                            setIsVariant({ Isvariant: false, product: product, showModal: showModal, quantity: productQyt })
                        }}
                    >
                        <div
                            className="relative mx-auto max-w-2/3 lt-sm:max-w-full lt-sm:w-full"
                            onClick={e => e.stopPropagation()}
                        >
                            {/*content*/}
                            <div className="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-neutral-100 outline-none focus:outline-none mb-16">
                                {/*body*/}
                                <div className="relative px-4 py-4 lt-sm:px-2 flex-auto">
                                    <div className="absolute p-2 right-0 top-0">
                                        <span
                                            className="cursor-pointer lt-sm:pt-1"
                                            onClick={() => { closeModal(false); setSelectedItem(null) }}
                                        >
                                            <AiOutlineClose />
                                        </span>
                                    </div>
                                    <div className="flex flex-row gap-2 flex-wrap pb-2">
                                        <span className="text-base text-neutral-800 font-semibold select-none whitespace-nowrap">Item Customization</span>
                                        {
                                            customizedItems?.filter((i) => i?.deleted != true)?.map((cartItem, index) => {
                                                return (
                                                    <div className={`flex gap-2 justify-center items-center rounded ${selectedItem?.internalId == cartItem?.internalId ? "bg-[#95B46A] text-white" : "text-success-500 border border-solid border-[#95B46A]"} overflow-hidden h-6`}>
                                                        <div className="flex flex-row gap-1 select-none h-full items-center pr-1">
                                                            <span className={`flex items-center h-full ${selectedItem?.internalId == cartItem?.internalId ? "bg-success-500" : ""} text-xs pl-2 pr-1`}>Custom:</span>
                                                            <span className="text-sm py-1">{cartItem?.quantity}</span>
                                                            {cartItem?.addon?.length > 0 &&
                                                                <span className="bg-success-400 text-white rounded-full px-2 text-xs">{cartItem?.addon?.length}</span>
                                                            }
                                                            <button className="p-0.5 text-superone" onClick={() => { if (cartItem?.internalId != selectedItem?.internalId) { setSelectedItem(cartItem) } else { setSelectedItem(null) } }}><MdEdit size="12" /></button>
                                                        </div>
                                                    </div>)
                                            })
                                        }
                                    </div>
                                    <div className={`flex gap-2 items-center pb-4 ${product?.name !== undefined && product?.name?.length < 40 ? "w-96" : "w-full"} lt-sm:w-full`}>
                                        <Image
                                            loader={sanityIoImageLoader}
                                            src={getImageObject(product.images)?.url || "https://jhattse.com/public/consumer/placeholder/product_square.png"}
                                            alt={product?.name || ''}
                                            width="80"
                                            height="80"
                                            className="rounded"
                                        />
                                        <span className="text-neutral-700 font-semibold text-base">{trimToLength(product?.name, 80)}</span>
                                    </div>
                                    {addonList?.length > 0 &&
                                    <div className="flex flex-row flex-wrap gap-2 h-6 items-center">
                                        {
                                            addonList?.filter((a) => a?.enable != false)?.map((addon, idx) => {
                                                return (
                                                    <div className="flex gap-2 justify-center items-center py-1 px-2 rounded bg-success-500 text-white text-xs" key={idx}>
                                                        <span className="select-none">{addon?.addon?.product.name}</span>
                                                        <button className="cursor-pointer text-white" onClick={() => { toggleAddon(addon) }}>x</button>
                                                    </div>
                                                )
                                            })
                                        }
                                    </div>}
                                    {
                                        product?.variants?.length > 0 &&
                                        <div>
                                            <h3 className="font-medium md:text-base text-sm py-2">Variants</h3>
                                            <CarouselContainer>
                                                {product?.variants?.length !== 0 && product?.variants?.map((item, index: number) => {
                                                    return <SwiperSlide style={{ width: "auto" }} className="min-w-0" key={index}>
                                                        <div className={(getFirst(inventoryByVariantId(item, product))?.is_available || inventory?.is_available) ? `bg-neutral-50 py-2 px-4 rounded-md cursor-pointer ${item?.id == variant?.id ? 'border-2 border-solid border-brand_cerulean' : 'border-2 border-solid border-neutral-300'}` : "bg-neutral-100 py-2 px-4 rounded-md opacity-75 border-solid border-neutral-300"} onClick={() => (getFirst(inventoryByVariantId(item, product))?.is_available || inventory?.is_available) && onClickVariant(item)
                                                        }>
                                                            <div className="flex flex-col ">
                                                                <div className="flex justify-start py-2 border-b border-b-solid border-neutral-200">
                                                                    <h4 className="text-lg font-semibold text-neutral-900 text-sm line-clamp-1">{trimToLength((item?.name || product?.name), 20)}</h4>
                                                                </div>
                                                                <span className="text-lg py-1 font-bold text-neutral-900 text-base">{humanizeCurrency(getFirst(inventoryByVariantId(item, product))?.price || getFirst(inventoryByVariantId(item, product))?.mrp || item?.mrp || 0)}</span>
                                                                <p className={(getFirst(inventoryByVariantId(item, product))?.is_available || inventory?.is_available) ? "font-bold text-sm text-success-400" : "font-bold text-sm text-error-400"}>{(getFirst(inventoryByVariantId(item, product))?.is_available || inventory?.is_available) ? "In Stock" : "Out of stock"}</p>
                                                            </div>
                                                        </div>
                                                    </SwiperSlide>
                                                })}
                                            </CarouselContainer>
                                        </div>
                                    }
                                    {
                                        addons?.length > 0 &&
                                        <>
                                            <div className="hidden md:block p-2">
                                                <h3 className="font-medium text-lg py-2 lt-sm:text-sm">Addons</h3>
                                                <CarouselContainer>
                                                    {addons?.length !== 0 && addons?.map((item, index: number) => {
                                                        return <SwiperSlide style={{ width: "auto" }} className="min-w-0" key={index}>
                                                            <div className={`bg-neutral-50 py-2 px-4 rounded-md cursor-pointer border-2 border-solid border-brand_cerulean ${addonList?.filter((a) => a?.enable != false)?.some((e) => e?.addon_id === item?.addon_id) ? 'border-2 border-solid border-brand_cerulean' : 'border-2 border-solid border-neutral-300'}`} onClick={() => { toggleAddon(item) }}>
                                                                <div className="flex flex-col gap-2 ">
                                                                    <div className="flex justify-start gap-2 py-2 border-b border-b-solid border-neutral-200">
                                                                        <h4 className="text-lg font-semibold text-neutral-900 text-sm line-clamp-1">{trimToLength((item?.addon?.product?.name), 20)}</h4>
                                                                    </div>
                                                                    <span className="text-lg py-1 font-bold text-neutral-900 text-base">{humanizeCurrency(item?.price)}</span>
                                                                    {/* <p className={ "font-bold text-sm text-error-400"}>{getFirst(inventoryByVariantId(item, product))?.is_available ? "In Stock" : "Out of stock"}</p> */}
                                                                </div>
                                                            </div>
                                                        </SwiperSlide>
                                                    })}
                                                </CarouselContainer>
                                            </div>
                                            <div className="block md:hidden p-2">
                                                <h3 className="font-medium text-lg py-2 lt-sm:text-sm">Addons</h3>
                                                <div className="flex flex-col gap-2">
                                                    {addons?.length !== 0 && addons?.map((item, index: number) => {
                                                        return <div className={`bg-neutral-50 py-2 px-4 rounded-md cursor-pointer border-2 border-solid border-brand_cerulean ${addonList?.filter((a) => a?.enable != false)?.some((e) => e?.addon_id === item?.addon_id) ? 'border-2 border-solid border-brand_cerulean' : 'border-2 border-solid border-neutral-300'}`} onClick={() => { toggleAddon(item) }}>
                                                            <div className="flex flex-row justify-between gap-2 ">
                                                                <div className="flex justify-start gap-2 py-2 border-b border-b-solid border-neutral-200">
                                                                    <h4 className="text-lg font-semibold text-neutral-900 text-sm line-clamp-1">{trimToLength((item?.addon?.product?.name), 20)}</h4>
                                                                </div>
                                                                <div className="flex gap-2">
                                                                    <span className="text-lg py-1 font-bold text-neutral-900 text-base">{humanizeCurrency(item?.price)}</span>
                                                                    {
                                                                        addonList?.filter((a) => a?.enable != false)?.some((e) => e?.addon_id === item?.addon_id) ?

                                                                            <span className="text-lg py-1 font-bold text-neutral-900 text-base">-</span>
                                                                            :
                                                                            <span className="text-lg py-1 font-bold text-neutral-900 text-base">+</span>
                                                                    }
                                                                </div>
                                                                {/* <p className={ "font-bold text-sm text-error-400"}>{getFirst(inventoryByVariantId(item, product))?.is_available ? "In Stock" : "Out of stock"}</p> */}
                                                            </div>
                                                        </div>

                                                    })}
                                                </div>
                                            </div>
                                        </>
                                    }
                                </div>
                                {/*footer*/}
                                <div className="flex items-center justify-center gap-2 px-4 py-2 lt-sm:px-2 rounded-b">
                                    <div className="flex flex-1 flex-row gap-4 items-center align-bottom">
                                        <div className={`grid grid-cols-3 font-bold border-2 border-brand_indigo text-brand_indigo min-w-32 md:text-base text-sm`}>
                                            <div className="col-span-1 flex justify-start items-center">
                                                <button className="flex items-center p-2 bg-brand_indigo text-neutral-50 rounded-md cursor-pointer" disabled={productQyt == 1} onClick={(event) => increase(-1)}>
                                                    <FaMinus />
                                                </button>
                                            </div>
                                            <div className="col-span-1 justify-center items-center p-2 text-center"><span>{productQyt}</span></div>
                                            <div className="col-span-1 flex justify-end items-center">
                                                <button className="flex items-center p-2 bg-brand_indigo text-neutral-50 rounded-md cursor-pointer" onClick={(event) => increase(1)}><FaPlus /></button>
                                            </div>
                                        </div>
                                    </div>
                                    <button
                                        className="flex-1 text-sm p-2 border-2 border-brand_indigo text-neutral-50 bg-brand_indigo rounded font-semibold font-manrope cursor-pointer"
                                        type="button"
                                        onClick={() => { onAddToCart(product, addonList, inventory || getFirst(inventoryByVariantId(variant, product)) as TInventory, productQyt, selectedItem?.internalId); setSelectedItem(null) }}
                                    >
                                        {`Add To Cart | ${humanizeCurrency(parseFloat(modalPriceCalculation(inventory || getFirst(inventoryByVariantId(variant, product)), productQyt, addonList)))}`}
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="opacity-25 fixed inset-0 z-40 bg-neutral-900"></div>
                </>
            ) : null}
        </div>
    )
}

export default Variant